<template>
  <div class="max-w-full">
    <common-room-chat-message-form-toolbar
      v-if="initMessage || relatedMessage"
      :message="(initMessage || relatedMessage) as Message"
      :is-reply="relatedMessage && !initMessage"
      @close="onCloseToolbar"
    />
    <form
      ref="formRef"
      class="relative md:p-5 px-3 py-4 flex gap-2.5 items-end"
      @submit="onSubmit"
    >
      <div class="flex">
        <div class="flex items-center">
          <lazy-ui-chat-emoji-picker @select-item="onSelectEmoji" />
          <div class="mx-2.5 h-5 w-px text-card-placeholder-border bg-current" />
        </div>
        <div class="flex items-center">
          <common-room-chat-upload-attachment
            :init-message="values.message"
            :disabled="!!chatStore.editMessageId"
            :is-modal="isModal"
          />
          <div class="ml-2.5 h-5 w-px text-card-placeholder-border bg-current" />
        </div>
      </div>
      <client-only>
        <ui-fields-editor
          ref="refEditor"
          name="message"
          :floating-ref="formRef"
          class="flex-1 min-h-5"
          :class="isModal ? 'max-h-32' : 'max-h-[50vh]'"
          :placeholder="placeholder"
          :disabled="isSubmitting"
          :get-mentions="onGetUser"
          @editor:blur="onBlurEditor"
          @submit="onSubmit"
        />
      </client-only>
      <ui-button
        class="shrink-0 text-2xl w-6 h-6"
        :loading="!isModal && isSubmitting"
        type="submit"
        :disabled="isDisabled"
        variant="link"
        size="xs-icon"
        icon-right="send"
      />
      <div
        v-if="isSubmitting"
        class="opacity-0 z-1 absolute inset-0"
      />
    </form>
    <ui-loader
      v-if="isModal"
      :loading="isSubmitting"
    />
  </div>
</template>

<script setup lang="ts">
  import type { EditorEvents } from '@tiptap/core';
  import type { Editor } from '@tiptap/vue-3';

  type Props = {
    isModal?: boolean;
    defaultMessage?: string;
    placeholder?: string;
  };
  const props = withDefaults(defineProps<Props>(), {
    placeholder: 'Your message'
  });
  const emits = defineEmits(['success']);
  const limitToasts = useLimitToasts();
  const roomStore = useRoomStore();
  const chatStore = useChatStore();
  const { user } = useAuthUser();
  const apiRoutes = useApiRoutes();

  const initMessage = computed(() => {
    if (chatStore.editMessageId) {
      return useFind(chatStore.messages, {
        id: chatStore.editMessageId
      });
    }
    return undefined;
  });

  const relatedMessage = computed(() => {
    if (chatStore.replayMessageId) {
      return useFind(chatStore.messages, {
        id: chatStore.replayMessageId
      });
    }
    return undefined;
  });

  const initialValues = {
    message: props.defaultMessage || ''
  };

  const formRef = ref<HTMLElement>();
  const refEditor = ref<{ editor: Editor }>();
  const lastPosition = ref(0);
  const { isSubmitting, handleSubmit, resetForm, setValues, values } = useForm({
    initialValues
  });

  const isDisabled = computed(() => {
    if (chatStore.editMessageId || props.isModal) {
      return false;
    }
    return !refEditor.value?.editor.getText().trim().length;
  });

  const onSubmit = handleSubmit(
    useSubmitHandler(
      form => {
        lastPosition.value = 0;
        // Todo: remove this when click after pasting is fixed
        form.message = form.message.replace(/<mention([^>]*)>(.*?)<\/mention>/g, '<a$1>$2</a>');
        // trim before or after br text;
        form.message = form.message
          .replace(/^(<p>\s*(<br\s?\/?>\s*)*)/, '<p>')
          .replace(/((<br\s?\/?>\s*)*\s*<\/p>)$/, '</p>');

        if (initMessage.value?.id) {
          if (!form.message && !initMessage.value.tenor_url && !initMessage.value.attachments?.length) {
            chatStore.onDeleteMessage(initMessage.value.id);
            return;
          }

          return chatStore.updateMessage(initMessage.value.id, {
            message: form.message || null
          });
        }
        return chatStore.createMessage({
          message: form.message || null,
          related_id: relatedMessage.value?.id
        });
      },
      initialValues,
      {
        isShowToastError: false,
        onValidate() {
          return !isDisabled.value;
        },
        onSuccess() {
          if (initMessage.value?.id) {
            chatStore.onSelectEditMessage(0);
          }
          if (relatedMessage.value) {
            chatStore.onSelectReplyMessage(0);
          }
          resetForm({
            values: {
              message: ''
            }
          });
          emits('success');
        },
        onError: limitToasts
      }
    )
  );

  const getResolvePosition = (pos = lastPosition.value): number => {
    const editor = refEditor.value?.editor;
    if (!editor) {
      return 0;
    }
    try {
      return editor.state.tr.doc.resolve(pos).pos;
    } catch (e) {
      console.error(e);
      return editor.state.doc.content.size;
    }
  };

  const onBlurEditor = ({ editor }: EditorEvents['blur']) => {
    lastPosition.value = editor.state.selection.$anchor.pos;
  };

  const onInsertContent = (text: string) => {
    const editor = refEditor.value?.editor;
    if (editor) {
      const pos = getResolvePosition();
      const opts = { updateSelection: true };
      if (pos) {
        editor.commands.insertContentAt(pos, text, opts);
      } else {
        editor.commands.insertContent(text, opts);
      }

      editor.commands.focus();
    }
  };

  const onGetUser = async (search: string) => {
    const res = await apiRoutes.rooms.getMembers(roomStore.roomId, {
      search,
      per_page: 6
    });
    return useFilter(res._data.data, ({ id }) => id !== user.value?.id);
  };

  const onCloseToolbar = () => {
    if (initMessage.value) {
      chatStore.onSelectEditMessage(0);
      return;
    }
    chatStore.onSelectReplyMessage(0);
  };

  const onSelectEmoji = (type: string, content: any) => {
    if (type === 'emoji') {
      onInsertContent(content as string);
      return;
    }
    if (type === 'gif') {
      chatStore.createMessage({
        related_id: relatedMessage.value?.id,
        tenor_url: content.media_formats.webp.url
      });
    }
  };

  watch(initMessage, val => {
    setValues({
      message: val?.message || ''
    });
  });

  watch(relatedMessage, val => {
    if (val) {
      refEditor.value?.editor?.commands.focus();
    }
  });

  defineExpose({
    values,
    setValues
  });
</script>
